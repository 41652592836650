<script>
import CMExamConfig from '../../classes/CMExamConfig'
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    createShowing: Boolean,
  },
  render() {
    return this.$scopedSlots.default({
    })
  },
  computed: {
    ...mapGetters({
      crmCourseLocations: 'crmCourseCities/items',
      crmExamLocations: 'crmExamLocation/items'
    }),
  },
  data: () => ({
    examCityId: '',
    examCityName: '',
  }),
  mounted() {
    this.getCourseLocations({cache: true})
    this.getExamLocation({cache: true})
  },
  methods: {
    ...mapActions({
      getCourseLocations: 'crmCourseCities/list',
      getExamLocation: 'crmExamLocation/list'
    }),

    getCurrentExamLocation() {
      if(!this.$route.query.city || !this.crmCourseLocations || !this.crmExamLocations) return ''
      if (this.$route.query.city === this.examCityId) return this.examCityName
      this.examCityId = this.$route.query.city;
      const courseLocation = this.crmCourseLocations.find(item => item.ID === +this.examCityId)
      const examLocation = this.crmExamLocations.find(item => item.indexOf(courseLocation.name) !== -1 )
      this.examCityName = examLocation;
      return examLocation ? examLocation : ''
    }
  },
  watch: {
    createShowing(visible) {
      if (visible) this.$emit('city:update', {
        location: this.getCurrentExamLocation()
      })
    }
  }
}
</script>
